<template>
  <div class="main_box">
    <div v-if="true" class="data_lists">
      <div v-for="(item,index) in lessons" :key="index" class="list" @click="clickLesson(item)">
        <img class="thumb" :src="item.thumb" alt>
        <p class="lesson_name">{{ item.lesson_shortname }}</p>
        <div class="botInfo">
          <img class="icon1" src="@/assets/image/peps.png" alt>
          <span class="count">{{ item.buy_count }}</span>
          <img class="icon2" src="@/assets/image/flower.png" alt>
          <span class="rate">{{ item.comment_rate }}%</span>
        </div>
      </div>
    </div>
    <!-- 暂无数据 -->
    <div v-else class="noDatasBox">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有发布主题哦~</p>
    </div>
  </div>
</template>
<script>
import { getLecturerLesson } from '@/api/mine'
export default {
  data() {
    return {
      lessons: [],
      brief: '',
      mark: ''
    }
  },
  created() {
    this.getLecturerLesson()
  },
  methods: {
    getLecturerLesson() {
      const info = {
        uid: this.$route.query.u
      }
      getLecturerLesson(info).then(res => {
        if (res.errNo === 0) {
          var avatar_url = ''
          var nick_name = ''
          this.lessons = res.result.lesson
          // this.brief = res.result.brief
          // if (res.result.mark.length > 0) {
          //   this.mark = res.result.mark
          //   // [0]
          // }
          avatar_url = res.result.avatar_url
          nick_name = res.result.nick_name
          this.$emit('briefShow', this.brief, this.mark, nick_name, avatar_url)
        }
      })
    },
    // 课程详情
    clickLesson(item) {
      const url = item.url
      window.open(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.main_box {
  width: 100%;
  min-height: 500px;
  background-color: #fff;
  .data_lists {
    width: 100%;
    background-color: #fff;
    margin: 0  auto;
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    .list {
      text-align: left;
      margin-top: 20px;
      width: 211px;
      height: 226px;
      margin-right: 16px;
      cursor: pointer;
      border: 1px solid #eee;
      .thumb {
        width: 100%;
        height: 162px;
      }
      .lesson_name {
        margin-left: 8px;
        width: calc(100% - 16px);
        height: 25px;
        margin-top: 8px;
        color: #333;
        font-size: 16px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .botInfo {
        width: 100%;
        // height: 30px;
        line-height: 30px;
      }
      .icon1 {
        margin-left: 8px;
        margin-top: -3px;
        width: 15px;
        height: 15px;
      }
      .count {
        margin-left: 5px;
        color: #999;
        font-size: 14px;
        display: inline-block;
      }
      .icon2 {
        margin-left: 25px;
        margin-top: -3px;
        width: 15px;
        height: 15px;
      }
      .rate {
        margin-left: 3px;
        color: #999;
        font-size: 14px;
        display: inline-block;
      }
    }
    .list:nth-child(5n) {
      margin-right: 0px;
    }
  }
}
</style>
